Formalis.ServiceLog.prototype.Resource = {
    Lang:{
        serviceLogObjEmpty:'Service Log object is empty',
        serviceLogMessageEmpty : 'Service Log message is empty',
        serviceLogTypeEmpty : 'Service Log type is empty',
        LogType : 'Log Type',
        LogMessage : 'LogMessage',
        LogTime : 'LogTime',
        LogServiceName : 'LogServiceName',
        LogDetail:'LogDetail'
    }
};