Formalis.InstantMessaging.MessageWindow.prototype.Resource = {
    Lang: {
        Invitations: 'Davetiyeler',
        InvitationDataIsNull: 'Davetiye Verisi yok',
        WaitingForOperator: 'Operatör bekleniyor',
        YouAreInSessionWith: '@UserInfo ile görüşmedesiniz.',
        YouHaveEndedTheSession: 'Oturumu Sonlandırdınız.',
        AreYouSureToEndSession: 'Sayfadan ayrılmanız oturumun sonlanmasına neden olacaktır!',        
        ProblemSendingMessage: '"@MessageText" gönderilemedi.',
        ProblemExportingSessionHistory: 'Bildirim Dönüştürme ile ilgili hata var. Lütfen sistem yöneticisi ile görüşünüz.',
        SessionShallEnd: 'Sayfadan ayrılırsanız oturum sonlandırılacaktır.',
        WritingMessage: 'Yazıyor...',
        SendEmailMessage: 'Konuşma metni başarılı bir şekilde email adresisinize gönderildi.',
        SendEmailError: 'Email gönderilirken bir hata oluştu.',
        GoOfflineTitle: 'Online Durum',
        GoOfflineSuccessText: 'Online Durumunuz, Offline olarak değiştirilmiştir.'
    }
};